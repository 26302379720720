import { config, Payload } from "./config";
import { Utils } from "./utils";

const settings = {
  endpoint: 'https://19y5i1f670.execute-api.eu-west-2.amazonaws.com/prod/lisa'
}

export class Api {
    private token: string;

    public setToken(token: string) {
        this.token = token;
    }

    public getToken(): string {
        return this.token;
    }
    /**
     * Swap hmrc code for access token
     */
    public async authorize(code: string) {
        const data = await this.call(this.token, "POST", "authorize", {code: code});
    }

    public async getClients() {
        const data = await this.call(this.token, "POST", "get-clients", {});
        if (data.status?.success)
            return data.data;
        return [];
    }

    public async getClient(investorId: string) {
        const data = await this.call(this.token, "POST", "get-client", {investorId: investorId});
        if (!data.status?.success)
            throw new Error("Investor not found");
        return data.data;
    }

    public async getApi() {
        const data = await this.call(this.token, "POST", "status", {});
    }

    /**
     * Execute any of the LISA API commands
     *
     * @param command
     * @param payload
     * @returns
     */
    public async lisa(command: string, payload: Payload): Promise<Payload> {
        const method = config.lisa_api[command];
        if (!method)
            throw new Error("Unknown LISA command: " + command);

        const data = await this.call(this.token, method, command, payload);
        if (!data.status?.success)
            throw new Error("LISA command failed: " + command);

        return data.data;
    }


    private async call(
        token: string,
        method: string = 'POST',
        command: string,
        data?: Record<string, any>,
        query?: Record<string, string>
    ): Promise<Record<string, any>> {
        const body = {
            command: command,
            data: data
        }

        try {
            Utils.showLoader();
            const url = this.appendQuery(settings.endpoint, query);
            const payload = {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: body ? JSON.stringify(body) : undefined, // Include body if provided
            }
            console.log("api.call: ", payload, url);
            const response = await fetch(url, payload);

            Utils.hideLoader();
            const data: Record<string, any> = await response.json();

            if (response.ok) {
                console.log('API response:', data);
                return data;
            } else {
                console.error(`API error: ${response.status}`, data);
                throw new Error(`HTTP error: ${response.status}`);
            }
        } catch (error) {
            console.error('Error:', error);
            Utils.hideLoader();
            return { status: { success: false }, error: String(error) };
        }
    }

    private appendQuery(url: string, params?: Record<string, string>): string {
        if (!params) return url;
        const queryString = new URLSearchParams(params).toString();
        return `${url}?${queryString}`;
    }
}