import { Utils } from "./utils";// TODO: use AWS SDK instead

const profile = {
    userPoolDomain: 'lisamanager', // Replace with your User Pool ID
    clientId: '5f910e49klcnvji6tavktthnc3', // Replace with your User Pool Client ID
    region: 'eu-west-2', // Replace with your AWS region
    redirectUri: 'https://lisamanager.com'
};

export class Cognito {
    // Function to construct Hosted UI URLs
    public url(action: string): string {
        const baseUrl = `https://${profile.userPoolDomain}.auth.${profile.region}.amazoncognito.com`;
        const url = `${baseUrl}/${action}?client_id=${profile.clientId}&response_type=code&redirect_uri=${profile.redirectUri}`;
        switch (action) {
            case 'login':
            case 'signup':
                return `${url}&scope=email+openid`;
            case 'logout':
                return `${url}&logout_uri=${profile.redirectUri}`;
            default:
                throw new Error('Invalid action for Hosted UI URL');
        }
    }

    public async convertToToken(code: string): Promise<string> {
        try {
            Utils.showLoader();
            const tokenEndpoint = `https://${profile.userPoolDomain}.auth.${profile.region}.amazoncognito.com/oauth2/token`;

            const response = await fetch(tokenEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    grant_type: 'authorization_code',
                    client_id: profile.clientId,
                    code: code,
                    redirect_uri: profile.redirectUri
                }).toString()
            });

            if (!response.ok)
                throw new Error(`HTTP error! status: ${response.status}`);

            const data = await response.json();
            if (!data.id_token)
                throw new Error('No token received in response');

            return data.id_token;

        } catch (error) {
            console.error('Failed to convert authorization code to token:', error);
            throw error; // Re-throw to allow handling by caller
        } finally {
            Utils.hideLoader();
        }
    }
}