import { Grid, h } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import { Api } from './api';
import { Utils } from './utils';
import { config } from "./config";

const settings = {
    hmrcSandbox: "https://test-www.tax.service.gov.uk/oauth/authorize",
    hmrcProduction: "https://www.tax.service.gov.uk/oauth/authorize",
    clientId: "GRm2WsyqGlc0jrmuPihwH3qLBsV3",
    redirectUri: 'https://lisamanager.com'
}

export class App {
    private client: Record<string, any>;
    private clients: Record<string, any>[];
    private grid: Grid;
    private eventGrid: Grid;
    private transactionGrid: Grid;
    private api: Api;

    constructor(api: Api) {
        this.api = api;
        this.client = {};
        this.client.details = {};
        this.clients = [];
        this.eventGrid = new Grid(config.grids.event);
        this.eventGrid.render(Utils.getElement(config.grid_ids.event));
        this.transactionGrid = new Grid(config.grids.transaction);
        this.transactionGrid.render(Utils.getElement(config.grid_ids.transaction));
    }

    public event(action: string) {
        console.log("event: " + action);
        if (action.startsWith('btn-'))
            action = action.substring(4); // Remove the first 4 characters

        Utils.populate(action, this.client.details);
        switch (action) {
            case 'listview':
                Utils.hide('client');
                Utils.show('grid');
                Utils.classToggle('.client-menu', 'disabled');
                break;
            default:
                Utils.show('overlay');
        }
    }

    public async get(action: string) {
        if (!action.startsWith('get-'))
            throw new Error ("Unexpected get action: " + action);

        const command = action.substring(4); // Remove the first 4 characters "get-"
        const payload = {
            investorId: this.client.details.investorId,
            accountId: this.client.details.accountId ?? null
        }
        const response = await this.api.lisa(command, payload);
        Utils.showInfo(command, response.data);
    }

    public async send(action: string, event: Event) {
        if (!action.startsWith('send-'))
            throw new Error ("Unexpected send action: " + action);

        const command = action.substring(5); // Remove the first 5 characters "send-"
        const valid = Utils.validate(command, event);
        if (valid) {
            Utils.hide(command);  // command will be the id of the form
            Utils.hide('overlay');

            const payload = {
                investorId: this.client.details.investorId,
                accountId: this.client.details.accountId ?? null,
                data: Utils.getValues(command)
            }
            console.log(payload, JSON.stringify(payload).replace(/"/g, '"'));
            this.client = await this.api.lisa(command, payload);
            this.refreshClient();
        }
    }

    public async showClient(investorId: string) {
        // history.pushState({ page: "https://lisamanager.com" }, '', "https://lisamanager.com"); // more sophistication required!
        Utils.classToggle('.client-menu', 'disabled');
        Utils.hide('grid');
        Utils.show
        console.log("Get investor " + investorId);
        this.client = await this.api.getClient(investorId);

        Utils.populate("client", this.client.details);
        const hasAccount = this.client.details.accountId?.trim();
        //Utils.showhide('btn-createOrTransferLisaAccount', !hasAccount);
        //Utils.showhide('btn-reportDeathOrTerminalIllness', hasAccount);

        if (this.client.event) {
            this.eventGrid.updateConfig({
                data: this.client.event,
            }).forceRender();
        }
        else
            Utils.getElement(config.grid_ids.event, true);

        if (this.client.transaction) {
            this.transactionGrid.updateConfig({
                data: this.client.transaction,
            }).forceRender();
        }
        else
            Utils.getElement(config.grid_ids.transaction, true);
    }

    private refreshClient() {
        Utils.populate("client", this.client.details);
        if (this.client.event) {
            console.log("refresh client", this.client.event);
            // const container = Utils.getElement(config.grid_ids.event, true);
            this.eventGrid.updateConfig({
                data: this.client.event,
            }).forceRender();
        }
        else
            Utils.getElement(config.grid_ids.event, true); // clear div

        if (this.client.transaction) {
            console.log("refresh client", this.client.transaction);
            // const container = Utils.getElement(config.grid_ids.event, true);
            this.transactionGrid.updateConfig({
                data: this.client.transaction,
            }).forceRender();
        }
        else
            Utils.getElement(config.grid_ids.transaction, true); // clear div
    }

    public async initialize() {
        const payload = await this.api.getClients();
        if (payload.error) {
            Utils.showInfo("Error", payload);
            return;
        }
        this.clients = payload;
        const settings = config.grids.details;
        settings.data = this.clients,
        this.grid = new Grid(settings);

        this.grid.render(Utils.getElement(config.grid_ids.details));
        this.grid.on('rowClick', (...args) => {
            const investorId = args[1].cells[0].data as string;
            this.showClient(investorId);
        });
    }

    public hmrcUrl(): string {
        return settings.hmrcSandbox + `?response_type=code&client_id=${settings.clientId}&scope=write:lisa%20read:lisa&state=${this.api.getToken()}&redirect_uri=${settings.redirectUri}`
    }

}
