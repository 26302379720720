// Import a local module (can also import npm packages if needed)
import { App } from './app';
import { Cognito } from './cognito';
import { Api } from './api';
import { config } from './config';
import { Utils } from './utils';

const cognito = new Cognito();
const api = new Api();
const app = new App(api);

console.log("app loaded");

// Event listener for buttons with class 'app-button'
document.addEventListener('click', (event) => {
    // console.log(event.target);
    if (event.target instanceof HTMLElement && event.target.classList.contains('app-button')) {
        const action = event.target.id;
        if (['login', 'signup', 'logout'].includes(action)) {
            window.location.href = cognito.url(action);
        }
        else if (action === 'authenticate') {
            console.log("Authenticate");
            window.location.href = app.hmrcUrl();
        }
        else
            app.event(action);
    }

    if (event.target instanceof HTMLElement && event.target.classList.contains('form-submit')) {
        const action = event.target.id;
        app.send(action, event);
    }

    if (event.target instanceof HTMLElement && event.target.classList.contains('menu-button')) {
        const action = event.target.id;
        app.get(action);
    }

    if (event.target instanceof HTMLElement && event.target.classList.contains('close-btn')) {
        const popup = event.target.closest('.popup');
        if (popup)
            Utils.hide(popup.id);
        Utils.hide('overlay');
    }
});

// Function to handle JWT retrieval after successful login
window.addEventListener('load', () => {
    const handleLoad = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');  // from Cognito OR HMRC
        const state = urlParams.get('state');  // HMRC
        const test = urlParams.get('test'); // useful for testing

        let token = "";

        if (code) {
            if (state) {
                console.log(code, state);
                token = state;
                api.setToken(token);
                api.authorize(code);
            }
            else {
                token = await cognito.convertToToken(code);
                api.setToken(token);
            }
            console.log(token);

            Utils.classToggle('.main-menu', 'hidden');            // document.getElementById('forms')?.classList.toggle('hidden');
            document.getElementById('intro')?.classList.toggle('hidden');

            Utils.hide('header');
            await app.initialize();
        }
        else if (test) {
            Utils.classToggle('.main-menu', 'hidden');
            document.getElementById('intro')?.classList.toggle('hidden');
            api.setToken(test);
            Utils.hide('header');
            await app.initialize();
        }

    };

    handleLoad();
});

document.getElementById('creationReason')?.addEventListener('change', (event) => {
    const target = event.target as HTMLSelectElement;
    const isNew = target.value === 'New';
    if (isNew)
        Utils.hide('transferAccountSection');
    else
        Utils.show('transferAccountSection');

    Utils.setDisabled('transferredFromAccountId', isNew);
    Utils.setDisabled('transferredFromLMRN', isNew);
    Utils.setDisabled('transferInDate', isNew);
});

