import { Payload, config } from "./config";

export class Utils {
    public static hide(id: string) {
        this.getElement(id).classList.add("hidden");
    }

    public static show(id: string) {
        this.getElement(id).classList.remove("hidden");
    }

    public static classToggle(classId: string, id: string) {
        const elements = document.querySelectorAll(classId);
        elements.forEach(element => {
            element.classList.toggle(id);
        });
    }

    public static idToggle(id: string, name: string) {
        this.getElement(id).classList.toggle(name);
    }

    public static showhide(id: string, show: boolean) {
        if (show)
            Utils.show(id);
        else
            Utils.hide(id);
    }

    public static getElement(id: string, clear: boolean = false): HTMLElement {
        const element = document.getElementById(id);
        if (!element)
            throw new Error("missing essential element: " + id);
        if (clear)
            element.innerHTML = "";
        return element;
    }

    public static populate(id: string, data: Payload) {
        console.log("populate " + id, data);
        const element = this.getElement(id);
        if (!element)
            return;

        if (element.classList.contains('hidden'))
            element.classList.remove('hidden');

        // Find all input elements within the container
        const inputs = element.querySelectorAll('input');

        inputs.forEach(input => {
            const id = input.id;
            // Check if the data object has a matching property
            if (id && id in data)
                input.value = String(data[id]);
            else
                input.value = "";
        });
    }

    public static getValues(id: string): Payload {
        const form = this.getElement(id);
        if (!form)
            throw new Error("Element not found: " + id);

        if (!form.classList.contains('hidden')) {
            form.classList.add('hidden');
        }

        // Find all input elements within the container
        const elements = form.querySelectorAll('input, select');

        const data: Payload = {};
        elements.forEach(element => {
            const formElement = element as HTMLInputElement | HTMLSelectElement;
            if (formElement.disabled)
                return;

            if (formElement.id) {
                let value: any;
                // Convert to number if input type is 'number'
                if (formElement instanceof HTMLInputElement && formElement.type === 'number') {
                    value = formElement.value ? Number(formElement.value) : '';
                } else {
                    if (formElement.value === "true")
                        value = true;
                    else if(formElement.value === "false")
                        value = false;
                    else
                        value = formElement.value;
                }
                console.log(formElement.id, config.map[id][formElement.id], value);
                const mappedKey = config.map[id]?.[formElement.id];
                if (mappedKey) {
                    if (mappedKey == "supersede") {  // special case - only include if it's visible
                        const div = this.getElement(id + "Supersede");
                        console.log(div);
                        if (div.classList.contains('hidden'))
                            return;
                    }
                    if (value !== null && value !== undefined && value !== '') {
                        if (!data[mappedKey]) {
                            data[mappedKey] = {}; // Initialize nested object if it doesn't exist
                        }
                        data[mappedKey][formElement.id] = value; // Assign value to the nested object
                    }
                }
                else
                    data[formElement.id] = value; // Assign value to the top-level object
            }
        });

        return data;
    }

    public static validate(id: string, event: Event): boolean {
        console.log(id, event);
        const form = this.getElement(id);
        if (!form)
            throw new Error("Element not found: " + id);

        const inputs = form.querySelectorAll('input');
        let isValid = true;
        let errorMessages: string[] = [];

        inputs.forEach((input) => {
            if (input.disabled)
                return;

            // Validate required
            if (input.required && !input.value) {
                errorMessages.push(`The field "${input.name}" is required.`);
                isValid = false;
                return; // skip pattern check
            }

            // Validate pattern
            const pattern = input.getAttribute('pattern');
            if (pattern) {
                const regex = new RegExp(pattern);
                if (!regex.test(input.value)) {
                    errorMessages.push(`The field "${input.name}" does not match the required pattern.`);
                    isValid = false;
                }
            }
        });

        if (!isValid) {
            console.log("finished validation failed");
            alert(`Please fix the following errors:\n\n${errorMessages.join('\n')}`);
        }
        else
            console.log("finished validation passed");
        return isValid;
    }

    public static showInfo(id: string, data: Payload) {
        console.log(id, data);
        let div = Utils.getElement('info-content');
        div.innerHTML = "<h2>" + Utils.camelToSentence(id) + "</h2><p>";
        for (const key in data) {
            div.innerHTML += `<b>${key}</b>: ${data[key]}<br />`;
        };
        Utils.show('info');
    }

    public static setDisabled(id: string, state: boolean) {
        const element = document.getElementById(id) as HTMLInputElement;
        if (element)
            element.disabled = state;
    }

    public static showLoader() {
        this.getElement(config.loader).classList.add("active");
    }

    public static hideLoader() {
        this.getElement(config.loader).classList.remove("active");
    }

    public static camelToSentence(text: string): string {
        return text
            .replace(/([A-Z])/g, ' $1')
            .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1 $2')
            .replace(/^./, str => str.toUpperCase())
            .trim();
    }
}


// Create a custom namespace under `window` and assign `Utils`
(window as any).lisaManagerApp = {
    Utils
};